.oneOfMainPage__subtitle {
    font-family: 'et-book';
    letter-spacing: 7px;
    font-size: 30px;
    color: white;
    margin: 0 auto 0;
}

@media screen and (max-width: 799px) {
    .oneOfMainPage__subtitle {
        font-size: 20px;
        letter-spacing: 10px;
        padding-top: 8px;
    }
}

@media screen and (max-width: 599px) {
    .oneOfMainPage__subtitle {
        font-size: 12px;
        letter-spacing: 10px;
        padding-top: 8px;
    }
}