.rbc-calendar {}

.react-big-calendar-week-view .rbc-event {
    font-family: Arial, sans-serif;
    font-size: 10px;
    color: white;
    /* Другие настройки шрифта */
}

/* Шрифт заголовка в ячейке */
.rbc-event-label {
    color: rgb(255, 255, 255);
    font-size: 6px !important;
}

/* Шрифт текста в ячейке */
.rbc-event-content {
    font-size: 5px;
}

/* Сама ячейка инф в расписании */
.rbc-event {
    /* background-color: rgb(97, 89, 84) !important; */
    border: none !important;
    width: 100% !important;
}

/* контейнер .rbc-event */
.rbc-events-container {
    margin-right: 0 !important;
}

/* цвет фона активной недели */
.rbc-today {
    background-color: rgba(0, 0, 0, 0.251) !important;
    background-color: transparent !important;
    /* Другие настройки цвета фона */
}

/* цвет текста для всего календаря для Дарк (добавлять к .trainig-calendar-container) */
.trainig-calendar-container_dark {
    color: rgba(255, 255, 255, 0.551) !important;
}

/* цвет рамки для Дарк */
.rbc-time-content_dark {
    border: 2px solid rgba(255, 255, 255, 0.551) !important;
}

@media (max-width: 900px) {
    .rbc-calendar {
        /* transform: rotate(90deg);
        transform-origin: top left;
        width: 100vh;
        height: 100vw; */
    }
}