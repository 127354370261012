.formadd__form {
    /* max-height: 830px; */
    width: 430px;
    /* background: #FFFFFF; */
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

@media screen and (max-width: 450px) {
    .formadd__form {
        width: 282px;
    }
}