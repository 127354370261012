.infotab__description-title {
    font-family: 'Montserrat-SemiBold','Inter', Arial, sans-serif;
    font-size: 15px;
    font-weight: 900;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    padding: 30px 0 10px;
    margin: 0;
    color: #ffffffe6;
}