.tooltip__button {
    border: none;
    background-color: rgb(0, 0, 0, 0.1);
    border-radius: 50%;
    min-width: 60px;
    max-width: 100px;
    min-height: 60px;
    margin: 0 auto;
    text-align: center;
    transition: opacity 0.8s;
    font-family: 'Inter';
    color: black;
    font-size: 14px;
    font-weight: bold;
}

.tooltip__button:hover {
    cursor: pointer;
    opacity: 0.8;
}