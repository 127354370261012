.about-client-page__text-active-visits {
    font-family: 'apple', Arial, sans-serif;;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0;
    text-align: center;
    margin: 0 0 7px;
    color: rgba(255, 255, 255, 0.7);
    background-color: black;
    border-radius: 10px;
    padding: 3px 10px;
    width: 120px;
}