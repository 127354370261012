.checkbox__option {
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    border-color: 'gray';
    justify-content: 'center';
    align-items: 'center';
}

input[type="checkbox"] {
    background-color: aqua;
}