.list-days__day {
    margin: 0;
    margin-right: 3px;
    padding: 0;
    font-family: 'apple';
    font-size: 17px;
    font-weight: bold;
    border: none;
    height: 56.5px;
    width: 56.5px;
    text-align: center;
    border-radius: 50%;
    transition: opacity 0.8s;
    background-color: transparent;
    transition: background-color 1.8s;
    color: black;
}

.list-days__day:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7) !important;
}

@media screen and (max-width: 599px) {
    .list-days__day {
        font-size: 14px;
        height: 35px;
        width: 35px;
        padding-bottom: 0px;
    }
}