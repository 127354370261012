.page__header {
    /* border-bottom: 1px solid rgb(255, 255, 255); */
    margin: 0 50px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 599px) {
    .page__header {}
}