.page__freeze-button {
    font-family: "Inter";
    padding: 0  !important;
    text-align: center !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border: none !important;
    /* min-height: 20px; */
    opacity: 0.7;
    margin-top: auto !important;
    margin-bottom: 50px !important;
    background-color: transparent !important;
}

.page__freeze-button:hover {
    cursor: pointer;
}