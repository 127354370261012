.signin__button-signup {
    background-color: transparent;
    color: black;
    font-size: 15px;
    font-family: 'Inter', Helvetica, Arial;
    border: none;
    width: 200px;
    height: 30px;
    margin: 90px auto;
    transition: opacity 0.7s;
}

.signin__button-signup:hover {
    cursor: pointer;
    opacity: 0.7;
}