.infotab {
    min-height: 100vh;
    position: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: calc(100vw);
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    /* background-color: rgba(255, 255, 255, 0.512); */
    background-color: rgba(37, 37, 37, 0.812) !important;
    /* Прозрачный фон */
    backdrop-filter: blur(10px) !important;
    /* Эффект размытия для матового стекла */
    transition: visibility 0.8s, opacity 0.5s, left 0.8s ease-out;
    /* z-index: 0; */
    max-width: 100vw;
    z-index: 9999;
    overflow: hidden;
    overflow-y: auto;
    /* Добавлено свойство overflow: hidden; */
}